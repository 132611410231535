import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '@material-ui/core';

PageBox.propTypes = {
  children: PropTypes.node,
};

export default function PageBox({ children }) {
  return (
    <Box>{children}</Box>
  );
}