import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect } from "react";

import { Typography, Box, Button } from "@material-ui/core";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { ApplicationContext } from "@product-site-frontend/shared";

import youtube from './images/ant-design_youtube-filled.svg';

CatalogSectionAd.propTypes = {
  type: PropTypes.string,
};

export default function CatalogSectionAd({ type }) {
  const style = {
    wrapperAd: {
      background: '#FFFFFF',
      border: '2px solid #FF780F',
      boxShadow: '0px 10px 24px rgba(255, 120, 15, 0.3)',
      borderRadius: '16px',
      padding: {
        xs: '20px ',
        sm: '20px',
        lg: type === 'horizon' ? '36px' : '20px',
      },
      display: type === 'horizon' ? 'flex' : null,
      flexWrap: {
        xs: 'wrap',
        sm: 'wrap',
        lg: 'nowrap'
      },
      marginTop: {
        xs: '28px',
        sm: '44px',
        lg: 0
      },
      marginBottom: {
        xs: '40px',
        sm: '40px',
        lg: type === 'horizon' ? '50px' : '50px',
      },
    },
    wrapperAdOne: {
      width: type === 'horizon' ? {
        xs: '100%',
        sm: '100%',
        lg: '80%'
      } : null,
      paddingRight: {sm: '0', lg: type === 'horizon' ? '40px' : '0px' },
    },
    title: {
      fontSize: {
        xs: '18px',
        sm: '24px',
        lg: type === 'horizon' ? '36px' : '22px'
      },
      fontWeight: 700,
      marginBottom: '20px',
      lineHeight: '1.2',

      '& > span': {
        color: '#FF780F',
      }
    },
    description: {
      color: '#0D1D32',
      fontSize: {
        xs: '12px',
        sm: '12px',
        lg: type === 'horizon' ? '17px' : '12px'
      },
      lineHeight: type === 'horizon' ? '30px' : '19.2px',
      marginBottom: '20px',
      fontWeight: 400,
    },
    blockDescriptions: {
      display: 'flex',
      flexWrap: {
        xs: 'wrap',
        sm: 'wrap',
        lg: type === 'horizon' ? 'nowrap': 'wrap'
      },
    },
    blockDescription: {
      fontSize: {
        sm: '20px',
        lg: type === 'horizon' ? '36px' : '22px'
      },
      lineHeight: '0.8',
      height: {
        xs: '50px',
        sm: '60px',
        lg: type === 'horizon' ? '67px' : '40px'
      },
      marginBottom: { sm: 0, lg: type === 'horizon' ? '30px' : '12px'},
      fontWeight: 500,
      width: { sm: 'calc(100% / 3)', lg: 'auto'},
      color: '#FF780F',

      '& > span': {
        fontSize: {
         xs: '12px',
         sm: '14px',
         lg: '14px',
        },
        color: '#0D1D32',
        width: '100%',
        display: 'inline-block',
        fontWeight: 400,
      }
    },
    blockBottom: {
      display: 'flex',
      // alignItems: type === 'vertical' ? 'flex-start' : 'center',
      alignItems: 'center',
      flexWrap: {
        xs: 'wrap',
        sm: 'nowrap',
        lg: type === 'vertical' ? 'wrap' : 'nowrap',
      },
      flexDirection: {
        xs: 'column-reverse',
        sm:  'inherit',
        lg:  type === 'vertical' ? 'column-reverse' : null,
      },
      justifyContent: {
        xs: 'center',
        sm: 'space-between',
        lg: 'space-between'
      },
      marginRight: {
        xs: '0',
        sm: '0',
        lg: type === 'vertical' ? '0' : '16px',
      }
    },
    button: {
      border: '1px solid #FF780F',
      borderRadius: '30px',
      padding: {
        sm: '12px 21px',
        lg: '12px'
      },
      margin: {
        xs: '0 0 14px 0',
        sm: type === 'vertical' ? '18px 0 20px 0' : '0 0 0 14px',
        lg: type === 'vertical' ? '18px 0 20px 0' : '0 0 0 14px',
      },
      fontSize: {
        xs: '14px',
        sm: '14px',
        lg: '16px'
      },
      minWidth: '160px',
      color: {
        xs: '#FF780F',
        sm: '#FF780F',
        lg: '#0D1D32'
      },
      textTransform: 'inherit',
      width: {
        xs: '100%',
        sm: '154px',
        lg: type === 'vertical' ? '100%' : '196px',
      },
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center'
    },
    iconButton: {
      display: {
        xs: '0',
        sm: 'none',
        lg: 'block'
      },
      color: '#FF780F'
    },
    wrapperYoutube: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      fontSize: '14px',
    },
    imgYoutube: {
      marginRight: '13px'
    },
    descriptionYoutube: {
      fontSize: {
        xs: '12px',
        sm: '14px',
        lg: '14px',
      },
      display: 'flex',
      alignItems: 'center',
      color: '#0D1D32',
      fontWeight: 500,
      lineHeight: '16px',
      height: type === 'vertical' ? '37px' : 'auto',
      paddingTop: type === 'vertical' ? '5px' : 'auto',
    }
  };

  const { setAmount, setApplicationOpen } = useContext(ApplicationContext);

  const handleApplicationClick = useCallback(() => {
    setAmount('');
    setApplicationOpen(true);
  }, [setApplicationOpen, setAmount]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('short_app')) {
      setApplicationOpen(true);
    }
  }, [setApplicationOpen]);

  return (
    <Box className={'infBlock'} sx={style.wrapperAd}>
      <Box sx={style.wrapperAdOne}>
        <Typography sx={style.title}>
          Лизинг оборудования <span>для вашего бизнеса</span>
        </Typography>

        <Typography sx={style.description}>
          от 100 000 до 10 000 000 руб на любые цели, получите новое оборудование от проверенных поставщиков за несколько дней, без посещения офиса.
        </Typography>
      </Box>

      <Box>
        <Box sx={style.blockDescriptions}>
          <Typography sx={style.blockDescription}>до 15 млн <span>руб. сумма сделки</span></Typography>
          <Typography sx={style.blockDescription}>от 0% <span>авансовый платеж</span></Typography>
          <Typography sx={style.blockDescription}>до 48 мес <span>Срок финансирования</span></Typography>
        </Box>

        <Box sx={style.blockBottom}>
          <a href={'https://www.youtube.com/watch?v=VJ1wK48wOQs'}>
            <Box sx={style.wrapperYoutube}>
              <Box component={"img"} src={youtube} sx={style.imgYoutube} />
              <Typography
                component={"span"}
                sx={style.descriptionYoutube}>
                Узнайте все о лизинге за 80 секунд
              </Typography>
            </Box>
          </a>

          <Button onClick={handleApplicationClick} sx={style.button}>
            Подать заявку <KeyboardArrowRightIcon sx={style.iconButton} />
          </Button>
        </Box>
      </Box>
    </Box>
  );
}